import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "../Sections/Login/Login"
import Panel from '../Sections/Overview/Panel';
import Services from "../Sections/Customer/Services";
import Chat from './../Sections/Chat/Chat'
import ProtectedRoute from "./ProtectedRoute"
import NewPassword from "../Sections/NewPassword/NewPassword";
import RecoverPassword from '../Sections/RecoverPassword/RecoverPassword';
import Users from "../Sections/Users/Users";
import InputPassword from '../Sections/RecoverPassword/InputPassword';
import Mantenimiento from '../mantenimiento/Mantenimiento';
import SupportCenter from "../SupportCenter/SupportCenter";



const Router = (props) => {

  return(
    
    <>
      <Switch>
  
        <Route path="/" exact component={()=>{
          if(localStorage.getItem(process.env.REACT_APP_SECRET_LOCAL_KEY)) {
            return <Redirect to = {
              {
                  pathname: "/login",
                  state: {
                      from: props.location
                  }
              }
            }/>         
          }
          
          return <Redirect to = {
            {
                pathname: "/logout",
                state: {
                    from: props.location
                }
            }
          }/>  
  
        }}/>
  
        <Route path="/login" exact component={Login}/>
        
        <Route path="/recover-password/:hash" exact component={InputPassword}/>
        
        <ProtectedRoute path="/home" exact component={Panel}/>
        
        <ProtectedRoute path="/atencion" exact component={Services}/>
        
        <ProtectedRoute path="/users" exact component={Users}/>
  
        <ProtectedRoute path="/supportcenter" exact component={SupportCenter} />
        
        <ProtectedRoute path="/atencion/chat/:_conversation/:_id/:ktbos_id" exact component={Chat}/>
        
        <ProtectedRoute path="/new-password" exact component={NewPassword}/>
        
        <Route path="/recover-password" exact component={RecoverPassword}/>
  
        <Route path="/mantenimiento" component={Mantenimiento}/>
  
        <Route path='/logout' component={() => {
  
          localStorage.removeItem(process.env.REACT_APP_SECRET_LOCAL_KEY);
  
          window.location.href = process.env.REACT_APP_LOGOUT_DOMAIN;
  
          return null;
          
        }}/>
        
      </Switch>
    </>
    );
}

export default Router;