import React from 'react';
import { Line } from 'react-chartjs-2';


const TimeClosingAvg = (props) => {
    const {monthsLabel, dataCategoriesResume, label1, label2 } = props;
   
    const {avg_time_month, avg_close_month} = dataCategoriesResume;
   
    const getAvgTimeMonth = () => {
        var out = avg_time_month;
        if(avg_time_month){
            if(avg_time_month.length === 1) {
                out = [null, avg_time_month[0], null];
            }
            /* console.log('getAvgTimeMonth', out) */
        }
        return out;
    }

    const getAvgCloseMonth = () => {
        var out = avg_close_month;
        if(avg_close_month){
            if(avg_close_month.length === 1) {
                out = [null, avg_close_month[0], null];
            }
        }
        /* console.log('getAvgCloseMonth', out) */
        return out;
    }

    const getMonthsLabel = () => {
        var out = monthsLabel;
        if(monthsLabel.length === 1) {
            out = ['nulo', monthsLabel[0], 'nulo' ];
        }
        return out;
    }

    const data = {
        labels:  getMonthsLabel(),
        datasets:[{
            label: label1,
            fill: false,
            lineTension: 0,
            backgroundColor: '#ff7534',
            borderColor: '#ff7534',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#ff7534',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#ff7534',
            pointHoverBorderColor: '#ff7534',
            pointHoverBorderWidth: 5,
            pointRadius: 5,
            pointHitRadius: 20,
            data: getAvgTimeMonth()
        }, {
            label: label2,
            fill: false,
            lineTension: 0,
            backgroundColor: '#99de14',
            borderColor: '#99de14',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#99de14',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#99de14',
            pointHoverBorderColor: '#99de14',
            pointHoverBorderWidth: 5,
            pointRadius: 5,
            pointHitRadius: 20,
            type: 'line',
            data: getAvgCloseMonth(),
        }]

    }
    return( <Line data={data}></Line> )
  
}

export default TimeClosingAvg;