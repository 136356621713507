import React, {useContext, useState, useEffect} from "react";
import './Period.scss';
import { Container, Row, Col, Card, Badge } from 'reactstrap';
import { contextApp } from '../../../App/contextApp';
import language from './language.json';


const Period = (props) =>{
    const { dataGeneral } = props;

    const {       
        currentLanguage
    } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

    },[currentLanguage])

    const mostTotal = () => {  
        if(dataGeneral.most) {
            if(dataGeneral.most[0]) {
                return dataGeneral.most[0].total
            }
            return 0;
        }
    }

    const mostName = () => {  
        if(dataGeneral.most) {
            if(dataGeneral.most[0]) {
                return dataGeneral.most[0].name
            }
            return '-';
        }
    }
    const lessTotal = () => {  
        if(dataGeneral.less) {
            if(dataGeneral.less[0]) {
                return dataGeneral.less[0].total
            }
            return 0;
        }
    }

    const lessName = () => {  
        if(dataGeneral.less) {
            if(dataGeneral.less[0]) {
                return dataGeneral.less[0].name
            }
            return '-';
        }
    }

    return (<Container tag="section">
            <Row className="mb-5">
                <Col xs="12">
                    <h4 className='emigre-bold text-secondary'>{texts.totalPeriod}</h4>
                    <Card body tag="article">
                        <Row>
                            <Col xs={12} md={4} className="border-right">
                                <h6 className="emigre-bold text-secondary text-center text-uppercase"><i className="icomoon icon-shop"></i> {texts.mostComplainedFranchise}</h6>
                                <Row className="justify-content-center my-4 align">
                                    <Col xs={4} className="d-flex align-items-center">
                                       <p className="whiteCapsule m-0">{dataGeneral.franchise_total ? dataGeneral.franchise_total : 0}</p>
                                    </Col>
                                    <Col xs={8} className="d-flex align-items-center justify-content-center">
                                    <p className="emigre-bold text-dark text-uppercase m-0">{dataGeneral.franchise ? dataGeneral.franchise : 'Franquicia'}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={4} className="border-right">
                                <h6 className="emigre-bold text-secondary text-center text-uppercase"><i className="icomoon icon-quejascc"></i> {texts.complaintsWithCommentary}</h6>
                                <Row className="justify-content-center my-4">
                                    <Col xs={4} className="d-flex align-items-center">
                                        <p className="grayCapsule m-0">{dataGeneral.clarifications ? dataGeneral.clarifications : 0}</p>
                                    </Col>
                                    <Col xs={8} className="d-flex align-items-center">
                                        <p className="emigre-bold text-dark m-0">{texts.ithascomments}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={4}>
                                <h6 className="emigre-bold text-secondary text-center text-uppercase"><i className="icomoon icon-cerrado"></i>{texts.incidentsNumbers}</h6>
                                <Row className="my-4">
                                    <Col xs={{size: 6, offset:3}} md={{size: 4, offset:4}} className="d-flex align-items-center justify-align-center">
                                       <p className="grayCapsule m-0">{dataGeneral.tickets ? dataGeneral.tickets : 0}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Card body tag="article" className="mt-4">
                        <Row>
                            <Col xs={12} md={3} className="border-right">
                                <h6 className="emigre-bold text-secondary text-center text-uppercase"><i className="icomoon icon-sucursal"></i>{texts.mostComplaintedBranches}</h6>
                                <Row className="justify-content-center mt-4">
                                    <Col xs={4}>
                                      <p className="whiteCapsule">{
                                          /* dataGeneral.most ? dataGeneral.most[0].total : 0 */
                                            mostTotal()
                                          
                                          }</p> 
                                    </Col>
                                    <Col xs={8} className="d-flex align-items-center justify-content-center">
                                       <p className="emigre-bold text-dark text-uppercase">{
                                           /* dataGeneral.most ? dataGeneral.most[0].name : 'Sucursal' */
                                           mostName()
                                           
                                        }</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={3} className="border-right">
                                <h6 className="emigre-bold text-secondary text-center text-uppercase"><i className="icomoon icon-sucursal"></i>{texts.lessComplaintedBranches}</h6>
                                <Row className="justify-content-center mt-4">
                                    <Col xs={4}>
                                       <p className="whiteCapsule">{
                                           /* dataGeneral.less ? dataGeneral.less[0].total : 0 */
                                           lessTotal()
                                           
                                           }
                                        </p>
                                    </Col>
                                    <Col xs={8} className="d-flex align-items-center justify-content-center">
                                       <p className="emigre-bold text-dark text-uppercase">{
                                           /* dataGeneral.less ? dataGeneral.less[0].name : 'Sucursal' */
                                           lessName()
                                           }
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={6}>
                                <h6 className="emigre-bold text-secondary text-center text-uppercase"><i className="icomoon icon-tag"></i>{texts.categorywithMostComplaints}</h6>
                                <Row className="justify-content-center align-items-center mt-4">
                                    <Col xs={12} lg={6} className="d-flex">
                                        <Col xs={4}>
                                            <p className="whiteCapsule m-0">{dataGeneral.cat_most_total ? dataGeneral.cat_most_total : 0}</p>
                                        </Col>
                                        <Col xs={8} className="d-flex align-items-center">
                                            <p className="emigre-bold text-dark justify-content-center mt-1 mb-1 text-uppercase">{texts.servicesMessage}</p>
                                        </Col>
                                    </Col>
                                    <Col xs={12} lg={6} className="d-flex">
                                        <Col xs={4}>
                                            <p className="whiteCapsule m-0 ">{0}</p>
                                        </Col>
                                        <Col xs={8}>
                                            <p className="emigre-bold text-dark justify-content-center mt-2 mb-2 text-uppercase">{texts.unanswered}</p>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Row>
                        <Col md={6}>
                            <Card body tag="article" className="mt-4">
                                <Row>
                                    <Col>
                                    <h6 className="emigre-bold text-secondary text-center text-uppercase">
                                        <i className="icomoon icon-quejascc"></i> 
                                        {texts.top5mostComplaints}
                                        </h6>
                                        <Container>

                                            {
                                                dataGeneral.most ?
                                                dataGeneral.most.map((ele, i)=>(
                                                    
                                                    <Row key={i}>
                                                        <Col xs={6}>
                                                            <h5 className="emigre-bold text-secondary text-uppercase">
                                                                { `${i + 1}. ${ele.name}` } 
                                                            </h5>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <h5 className="emigre-bold text-secondary text-right">

                                                                <Badge color="primary" className="line-izq text-white py-2 px-3 ml-4 badge-line1">
                                                                    {ele.total}
                                                                </Badge>
                                                            </h5>
                                                        </Col>
                                                    </Row>
                                                ))

                                                : null
                                            }

                                        </Container>

                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card body tag="article" className="mt-4">
                                <Row>
                                    <Col>
                                    <h6 className="emigre-bold text-secondary text-center text-uppercase">
                                        <i className="icomoon icon-quejascc"></i> 
                                        {texts.top5lessComplaints}</h6>
                                        <Container>

                                        {
                                            dataGeneral.less ?
                                            dataGeneral.less.map((ele, i)=>(
                                                
                                                <li  className='top_5' key={i}>
                                                    <Row key={i}>
                                                        <Col xs={6}>
                                                            <h5 className="emigre-bold text-secondary text-uppercase">
                                                                { `${i + 1}. ${ele.name}` } 
                                                            </h5>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <h5 className="emigre-bold text-secondary text-right">

                                                                <Badge color="primary" className="line-izq text-white py-2 px-3 ml-4 badge-line1">
                                                                    {ele.total}
                                                                </Badge>
                                                            </h5>
                                                        </Col>
                                                    </Row>
                                                    
                                        
                                                </li>

                                            ))

                                            : null
                                        }
                                        </Container>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row> 
                </Col>
            </Row> 
    
    </Container>)
    }


export default Period