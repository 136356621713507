/* eslint-disable */
import React, { useState, useEffect } from "react"
import { Container, Spinner, Card, Button, CardHeader, CardBody, InputGroup, InputGroupAddon, Input, Modal, ModalBody, FormFeedback } from 'reactstrap';
import HttpHandlerServices from '../../../../services/httpService';
import Select from 'react-select';
import '../Users.scss'
import { ToastContainer, toast } from 'react-toastify';


const AddNewUser = (props) => {


    /* const [registerData, setRegisterData] = useState({
        nameUser: '',
        newUserEmail: '',
        typeUser: '',
      });
 */


    const getDataNewUser = (e) =>{
        setDataNewUser({...dataNewUser, [e.target.name]: e.target.value});
    }

    const onSubmitNewUser = () => {

        const httpHandlerServices = new HttpHandlerServices();

        const endpoint = '/adduser';
        
        const data = {
            name: dataNewUser.nameUser,
            email: dataNewUser.newUserEmail,
            role: dataNewUser.typeUser,
            franchise_id: dataNewUser.franchiseUser
        }
        
        setLoader(true);
        httpHandlerServices.postMethod(endpoint, data).then(
            async (res)=> {
                const data = await res;
             
                if(data) {
                    setLoader(false);
                    switch(data.code){
                        case 101:
        
                            if(data.message === 'user maximum per franchise is 5') {
                                
                                toast.warning(`HA ALCANZADO EL MAXIMO DE USUARIO POR FRANQUICIAS`, {
    
                                    position: toast.POSITION.BOTTOM_RIGHT,
            
                                    autoClose: 8000
            
                                });
                            } else {
                                toast.warning(`EL CORREO ELECTRONICO YA ESTA REGISTRADO`, {
    
                                    position: toast.POSITION.BOTTOM_RIGHT,
            
                                    autoClose: 8000
            
                                });
                            }
                            
            
                            break;
                        default:
                            toggle();
                    }
                }
                

            }, async(error) => {
                
                setLoader(false);
                
                const data = await error;

                if(data) {

                    toast.warning(`SE PRODUJO UN ERROR AL INTENTAR CREAR UN USUARIO NUEVO, POR FAVOR VERIFIQUE SU INTERNET Y VUELVA A INTENTAR`, {
    
                        position: toast.POSITION.BOTTOM_RIGHT,
    
                        autoClose: 8000
    
                    });
                }

            }
        )
    }

    //show modal

    const {
        className
    } = props;
    
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [roles, setRoles] = useState([]);

    const [franchises, setFranchises] = useState([]);

    const getRoles = () => {
       const x = new HttpHandlerServices()

       const roles = '/showroles';

       x.getMethod(roles)
       .then(
            async (res) => {
                const data = await res;
                const array = []
                data[0].map(el => {
                    const item = {
                        value: el.id,
                        label: el.name,
                    }
                    array.push(item)
                })
                setRoles(array)
            }, async (error) => {
            const data = await error;
        })
    }

    const getFrachise = () => {
        
        const x = new HttpHandlerServices()
        
        const franchise = '/showfranchise';
        x.getMethod(franchise)
        .then(
            async (res) => {
                const data = await res;
                const arrays = []
                data.map(ele => {
                    const item = {
                        value: ele.id,
                        label: ele.franchise,
                    }
                    
                    arrays.push(item)
                })
                setFranchises(arrays)
            }, async (error) => {
            const data = await error;
            if(data) {
                return error;
            }
        })
    }

    const [disabled, setDisabled] = useState(true);
    const [dataNewUser, setDataNewUser] = useState({
        nameUser: '',
        newUserEmail: '',
        typeUser: '',
        franchiseUser: ''
    });

    useEffect(()=>{
        var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (dataNewUser.typeUser !== '' 
        && dataNewUser.franchiseUser !== ''
        && dataNewUser.nameUser !== '' 
        && dataNewUser.newUserEmail.match(mailformat)
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
        
    },[dataNewUser])


    const [loader, setLoader] = useState(false);
    const [selectedRoles, setselectedRoles] = useState(null);
    const [selectedFranchises, setselectedFranchises] = useState(null);

    useEffect(() => {
        getRoles()
        getFrachise()
    }, [])

    return (
        <div className='pt-2' id='addNewUser'>
            <Container>
            <ToastContainer />
                <Card>
                    <CardHeader className="text-uppercase emigre-bold text-white">
                        <h5 className="mb-0"><a href="/users" className="text-white"><i className="material-icons align-bottom">keyboard_arrow_left</i> regresar al listado de usuarios </a></h5>
                    </CardHeader> 
                    <CardBody className="d-flex justify-content-center mt-5 mb-5">
                        <div className="flex-column">
                            <h4 className="text-uppercase emigre-bold">agrega un usuario</h4>
                            <p className="text-uppercase emigre-bold">selecciona una opción en cada recuadro. <br/> Recuerde que todos los campos son obligatorios.</p>
                            <Select
                            className="mb-2"
                            classNamePrefix="select"
                            placeholder='ELIGE UN TIPO DE USUARIO'
                            value={selectedRoles}
                            options={roles}
                            name="typeuser" 
                            id="typeUser"
                            onChange={(e)=> {
                                setDataNewUser({...dataNewUser, typeUser: e.value});
                                setselectedRoles(e)
                            }} 
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => 'Ya no hay más opciones'}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: '#ff7534',
                                },
                              })}
                            />
                            
                            <Select
                            className="mb-2"
                            classNamePrefix="select"
                            placeholder='ELIGE UNA FRANQUICIA'
                            value={selectedFranchises}
                            options={franchises}
                            name="franchiseUser" 
                            id="franchiseUser"
                            onChange={(e) => {
                                setDataNewUser({...dataNewUser, franchiseUser: e.value})
                                setselectedFranchises(e)
                            }} 
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => 'Ya no hay más opciones'}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: '#ff7534',
                                },
                              })}
                            />

                            <p className="text-uppercase emigre-bold text-secondary">escribe los datos del usuario.<br/> (*campos obligatorios)</p>
                            <InputGroup className="mb-2">
                                <InputGroupAddon addonType="prepend">
                                </InputGroupAddon>
                                <Input name="nameUser" 
                                    id="nameUser" 
                                    placeholder="NOMBRE COMPLETO*" 
                                    value={dataNewUser.nameUser} 
                                    onChange={(e)=>{getDataNewUser(e)}} 
                                   
                                    required/>
                                <FormFeedback color="danger" className="my-2 text-center"><span className="text-white bg-danger rounded-circle mr-1">i</span>Campo obligatorio</FormFeedback>
                            </InputGroup>
                            <InputGroup className="mb-5">
                                <Input type="email" 
                                    name="newUserEmail" 
                                    id="newUserEmail" 
                                    placeholder="CORREO ELECTRÓNICO*" 
                                    value={dataNewUser.newUserEmail} 
                                    onChange={(e)=>{getDataNewUser(e)}} 
                                   />
                                <FormFeedback color="danger" className="my-2 text-center"><span className="text-white bg-danger rounded-circle mr-1">i</span>Proporciona un correo válido</FormFeedback>
                            </InputGroup>
                            
                            <Button 
                            color="primary" 
                            size="lg" className="text-uppercase emigre-bold text-white add-user" 
                            onClick={onSubmitNewUser}
                            disabled={disabled}
                            >
                                Agregar usuario
                            </Button>
                            <div className='full_width text-center'>
                            { loader ? <Spinner style={{ width: '3rem', height: '3rem' }} color="secondary" /> : null}
                            </div>

                            
                            <Modal isOpen={modal} toggle={toggle} className={className}>
                                <ModalBody>
                                    <h4 className="text-uppercase emigre-bold text-dark text-center">Los datos del usuario <br/> se han guardado correctamente</h4>    
                                    <p className="text-center">Elige una opción para continuar</p>
                                    <div className="d-flex justify-content-center">
                                        <Button color="primary" onClick={
                                            ()=> {
                                                window.location.reload();
                                            }
                                        } className="text-uppercase text-white emigre-bold mr-4">Regresar al listado</Button>
                                        <Button color="dark" onClick={
                                            ()=> {
                                                setDataNewUser({
                                                    nameUser: '',
                                                    newUserEmail: '',
                                                    typeUser: '',
                                                    franchiseUser: ''
                                                });
                                                
                                                setselectedRoles(null)
                                                setselectedFranchises(null)

                                                
                                                toggle();
                                            }
                                        } className="text-uppercase text-white emigre-bold">Crear nuevo usuario</Button>
                                    </div>
                                </ModalBody>
                            </Modal>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default AddNewUser;