import React, { useEffect, useState } from "react";
import { Container, Row, Col, Input } from 'reactstrap';
import HttpHandlerServices from '../../../services/httpService';
import { toast } from 'react-toastify';
import dots from '../../../assets/img/dots.gif';
import pizza from '../../../assets/img/makefg.png';
import send from '../../../assets/img/send.png';

import { w3cwebsocket as W3CWebSocket } from "websocket";
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';

const ChatViewer = (props) => {

    const { current, chatInfo } = props;

    const [conversation, setConversation] = useState([]);

    const [preConversation, setPreConversation] = useState([]);

    const [message, setMessage] = useState([]);

    const [text, setText] =useState("");

    const [userData, setUserData] = useState({
        id: null,
        name: null,
        email: null,
        role: null   
    });

    const [pending, setPending] = useState([])

    const [client, setClient] = useState(null);

    function updateScroll(){

        var element = document.getElementById("chat-panel");

        if(element) element.scrollTop = element.scrollHeight;

    }

    useEffect(()=> {
    
        setClient(new W3CWebSocket(process.env.REACT_APP_WS))
       

    },[current]);

    useEffect(()=> {

        setConversation([])
        setPreConversation([])
        setMessage([])

        if(client) {

            getUserData();
    
            getConversation();
    
            handlerSocket();
    
            updateScroll();
        }

    // eslint-disable-next-line
    },[client, current]);

    const getConversation = () => {

        setPending([1])

        const endpoint = `/showconversationsinit/${current.id}`;

        const httpHandlerServices =  new HttpHandlerServices();

        httpHandlerServices.getMethod(endpoint).then(

            async (res) => {

                const data = await res;

                chatInfo(data)

                const array = data.messages

                const convers = array.sort(function (a, b) {
                    var dateA = new Date(a.created_at), dateB = new Date(b.created_at)
                    return dateA - dateB
                });

                console.log('conversacion', convers)
                setPreConversation([...convers]);

                setPending([]);

                updateScroll();


            },
            async (error) => {
                const err = await error;
                if(err) {
    
                    toast.error(`SOMETHING WRONG HAPPENS`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 8000
                    });
                }
            }
        );
    }

    const getUserData = () => {

        const endpoint = '/user';

        const httpHandlerServices = new HttpHandlerServices();

        httpHandlerServices.getMethod(endpoint).then(

            async res => {


                const data = await res;

                setUserData(data);

            },
            error => {

                toast.error(`SOMETHING WRONG HAPPENS`
                , {

                    position: toast.POSITION.BOTTOM_RIGHT,

                    autoClose: 5000

                });
            }
        );
    }

    const handlerSocket = () => {

        
        client.onerror = function() {
            
            toast.error(`ERROR STARTING CHAT`
            , {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 8000
            });
        };
        
        client.onopen = function() {
        
            function subscribe() {

                
                if (client.readyState === client.OPEN) {
                    
                    client.send(`{"command" : "subscribe", "channel" : ${current.ktbots_id}}`)
                    
                }
            }
            
            subscribe();
            
        };
        
        client.onclose = function() {

            setClient(new W3CWebSocket(process.env.REACT_APP_WS))

        };
        
        client.onmessage = function(e) {

            if (typeof e.data === 'string') {

                let mesages = message;
                
                const res = JSON.parse(e.data);

                console.log('from Channel',current.ktbots_id ,'msg:',res)

                if(parseInt(res.ktbots_id) === parseInt(current.ktbots_id)) {

                    mesages.push(res);
                    
                    setMessage(message);
                    
                    setConversation([...mesages]);
    
                    let pendingArray = [...pending]
    
                    pendingArray.splice(0, 1);
                   
                    setPending(pendingArray);
                }

                updateScroll();

            } 
        };
    }

    const sendMesg = () => {

        
        setPending(['new', ...pending]);
        
        updateScroll();
        
        let message = text;
        
        let data = JSON.stringify({ "command" : "message", "message": message, "type" : "cms","cms_id" : userData.id});
        
        if (client.readyState === client.OPEN) {
            console.log('enviando')

            client.send(`{"command" : "subscribe", "channel" : ${current.ktbots_id}}`)
            
            client.send(data);

        } else {
            console.log('nuevo socket')
            setClient(new W3CWebSocket(process.env.REACT_APP_WS))

        }

        setText('');   

    }

    const timeZoneTime = (currenTime) => {
        
        const time = currenTime
        const a = moment.tz(time, process.env.REACT_APP_TIMEZONE);
        return a
    }

    return(
        <div className="chat-viewer">
            <div className="chat-header">
                <p>
                    <Moment format="YYYY/MM/DD" >
                        {current.created_at}
                    </Moment> 
                    <span> </span>
                    <Moment fromNow ago>{timeZoneTime(current.created_at)}</Moment>
                    
                </p>
                <div className="info-container">
                    <div className="client-name">{current.name}</div>
                    <div className="tags">
                   
                        <span className='tag'>
                            <i className="icomoon icon-tag"></i> 
                            {current.complaint_reason === 'more 15 with queue' && ' More than 15 Minutes'} 
                            {current.complaint_reason === 'more 10 with queue' && ' More than 10 Minutes'}
                            {current.complaint_reason === 'more 5 with queue' && ' More than 5 Minutes'}
                            {current.complaint_reason !== 'more 15 with queue' && current.complaint_reason !== 'more 10 with queue' && current.complaint_reason !== 'more 5 with queue' ? current.complaint_reason : ''}
                        </span>
                       
                    </div>
                    <div className="status">
                        {props.status === 'green' && <span className='green'></span>}
                        {props.status === 'yellow' && <span className='yellow'></span>}
                        {props.status === 'red' && <span className='red'></span>}
                        
                    </div>
                </div>
            </div>
            <div id='chat-panel' className='chat-panel'>
                <Container>
                    <Row className="message-container">
                        {
                            preConversation.map((ele, i) => (
                                <Col xs={12} key={ele.id}>
                                    {    /* CLIENT MESSAGE */
                                        ele.type === 'user' && 
                                        <Row className="message-row">
                            
                                            <Col xs={2} md={1} className="text-left flex-center">
                                                <i className="chat_icon icomoon icon-user_service"></i>
                                            </Col>
                                            <Col xs={10} md={11} className="p-0 text-left">
                                                <p className="emigre text-secondary m-0"><small><Moment fromNow ago>{timeZoneTime(ele.created_at)}</Moment></small></p>
                                                <p className={"text-dark m-0 emigre chat-text client capitalize titleLine" }>
                                                    {ele.type === 'user' ? current.name : ele.cms_name}
                                                </p>
                                                {ele?.image64 ? <img className='imgChat' src={`data:image/jpeg;base64,${ele.image64}`} alt=''/> :
                                                
                                                <p className={"text-dark m-0 emigre chat-text client" }>
                                                    {ele?.message !== '' && ele?.image64 === '' && ele?.message}
                                                </p>
                                                }
                                                
                                            </Col>
                                        </Row> 

                                    }
                                    {   /* FACEBOOK AUTO MESSAGE */
                                         ele.cms_id === 1  && ele.viewed === 2 &&  ele.type === 'cms'  && 
                                        <Row className="message-row">
                                            <Col xs={2} md={1} className="text-left flex-center">
                                                <img className='auto-response-icon' src={pizza} alt='pizza-icon' />
                                            </Col>
                                            <Col xs={10} md={11} className="p-0 text-left">
                                                <p className="emigre text-secondary m-0"><small><Moment fromNow ago>{timeZoneTime(ele.created_at)}</Moment></small></p>
                                                <p className={"text-dark m-0 emigre chat-text client capitalize titleLine" }>
                                                    FACEBOOK
                                                </p>
                                                <p className={"text-dark m-0 emigre chat-text client" }>
                                                    {ele?.message !== '' && ele?.image64 === '' && ele?.message}
                                                </p>
                                                {ele?.image64 && <img className='imgChat' src={`data:image/jpeg;base64,${ele.image64}`} alt=''/>}
                                            </Col>
                                        </Row>
                                         
                                    } 
                                    {   /* SUPPORT */
                                        ele.type === 'cms' && 
                                        <Row className="message-row">
                                            <Col xs={10} md={11} className="p-0 text-right">
                                                <p className="emigre text-secondary m-0"><small><Moment fromNow ago>{timeZoneTime(ele.created_at)}</Moment></small></p>
                                                <p className={"text-dark m-0 emigre chat-text operator capitalize titleLine" }>
                                                    {ele.type === 'user' ? current.name : ele.cms_name}
                                                </p>
                                                <p className={"text-dark m-0 emigre chat-text operator " }>
                                                    {ele?.message}
                                                </p>
                                            </Col>
                                            <Col xs={2} md={1} className="text-right flex-center">
                                                <i className="chat_icon icomoon icomoon icon-atc"></i>
                                            </Col>
                                        </Row>
                                    } 
                                    
                                </Col>

                            ))
                        }
                        {
                            conversation.map((ele, i) => (
                                <Col xs={12} key={i}>
                                    {    /* CLIENT MESSAGE */
                                        ele.type === 'user' &&
                                        <Row className="message-row">
                                            <Col xs={2} md={1} className="text-left flex-center">
                                                <i className="chat_icon icomoon icon-user_service"></i>
                                            </Col>
                                            <Col xs={10} md={11} className="p-0 text-left">
                                                <p className="emigre text-secondary m-0"><small><Moment fromNow ago>{ele.created_at}</Moment></small></p>
                                                <p className={"text-dark m-0 emigre chat-text client capitalize titleLine" }>
                                                    {ele.type === 'user' ? current.name : ele.cms_name}
                                                </p>
                                               
                                                {ele?.image64 ? <img className='imgChat' src={`data:image/jpeg;base64,${ele.image64}`} alt=''/> :
                                                
                                                <p className={"text-dark m-0 emigre chat-text client" }>
                                                    {ele?.message !== '' && ele?.image64 === '' && ele?.message}
                                                </p>
                                                }
                                                
                                            </Col>
                                        </Row> 

                                    }
                                    {     /* FACEBOOK AUTO MESSAGE */
                                        ele.cms_id === 1  && ele.viewed === -1 && ele.type === 'user' &&
                                        <Row className="message-row">
                                            <Col xs={2} md={1} className="text-left flex-center">
                                                <img className='auto-response-icon' src={pizza} alt='pizza-icon' />
                                            </Col>
                                            <Col xs={10} md={11} className="p-0 text-left">
                                                <p className="emigre text-secondary m-0"><small><Moment fromNow ago>{ele.created_at}</Moment></small></p>
                                                <p className={"text-dark m-0 emigre chat-text client capitalize titleLine" }>
                                                    FACEBOOK
                                                </p>
                                                <p className={"text-dark m-0 emigre chat-text client" }>
                                                    {ele?.message}
                                                </p>
                                            </Col>
                                        </Row> 
                                    }

                                    {   /* SUPPORT */
                                        ele.type === "cms" &&
                                        <Row className="message-row">
                                            <Col xs={10} md={11} className="p-0 text-right">
                                                <p className="emigre text-secondary m-0"><small><Moment fromNow ago>{ele.created_at}</Moment></small></p>
                                                <p className={"text-dark m-0 emigre chat-text operator capitalize titleLine" }>
                                                    {ele.type === 'user' ? current.name : ele.cms_name}
                                                </p>
                                                <p className={"text-dark m-0 emigre chat-text operator " }>
                                                    {ele?.message}
                                                </p>
                                            </Col>
                                            <Col xs={2} md={1} className="text-right flex-center">
                                                <i className="chat_icon icomoon icomoon icon-atc"></i>
                                            </Col>
                                        </Row>
                                         
                                    } 
                                </Col>
                            ))
                        }
                        {
                            pending.map(ele => (
                                
                                <div key={ele} className='dots'>
                                    <img src={dots} alt='dots' />
                                </div>

                            ))

                        } 
                        
                    </Row>
                </Container>
            </div>
            <div>
                <Container>
                    <Row>
                        <Col xs={12}>
                               
                            <Row className="mb-4 mt-3">
                                <Col xs={11}>
                                    <Input 
                                    type="text" 
                                    placeholder="" 
                                    className=""
                                    id='message'
                                    name='message'
                                    value={text}
                                    onChange={(e)=>{
                                        setText(e.target.value)
                                    }}

                                    onKeyDown={
                                        (e)=> {
                                            if(e.key === 'Enter' && text !== ''){
                                                sendMesg()
                                            }
                                        }
                                    }></Input>
                                </Col>
                                <Col xs={1}>
                                    <a href='/#'className="send-icon" onClick={
                                        (e)=> {
                                            e.preventDefault()
                                            sendMesg()
                                        }
                                    }>
                                        <img src={send} alt='send-icon'/>
                                    </a>
                                </Col>
                            </Row>
                                     
                        </Col>
                
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default ChatViewer;