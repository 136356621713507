import React, {useState} from 'react';
import './RecoverPassword.scss';
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Input, CardText, Button , FormFeedback} from 'reactstrap';
import logo from '../../../assets/img/logo-orange.png';
import HttpHandlerServices from '../../../services/httpService';

const RecoverPassword = () => {

    const [email, setEmail] = useState('');
    const [validate, setValidate] = useState();
    const [none, setNone] = useState('d-none');


    const requestLink = () => {

        const httpHandlerServices = new HttpHandlerServices();
        
        const endpoint = '/recovery';

        const data = {
            email: email ? email : null
        }

        httpHandlerServices.postMethod(endpoint, data).then(
            async res => {
                const data = await res;
                if(data) {
                    setNone('');
                }
            },
            async err => {
                const error = await err;
                if (error){
                   return error;
                }
            }
        );
        
    }

    const sendMail = () => {

        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)){

            requestLink();

            setValidate(false);

    
        } else {

            setValidate(true);

            setNone('d-none');

        }
    }

    const onchange = (e) => {

        setEmail(e.target.value);

    }

    return(

        <Container className="login">
            <Row>
                <Col>
                    <Card className="mt-5 p-3">
                        <CardTitle align="center">
                            <img src={logo} className="img-fluid mb-3 mt-5" alt="Little Caesars"/>
                            <h4 className="text-dark emigre-bold text-uppercase">recuperación de contraseña</h4>
                            <p className="text-secondary">Ingresa tu correo electrónico para contraseña.</p>
                        </CardTitle>
                        <CardBody>
                            <FormGroup>
                                <Input 
                                    type="email" 
                                    name="usermail" 
                                    id="usermail" 
                                    placeholder="CORREO ELECTRÓNICO" 
                                    className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold"
                                    onChange={(e)=>onchange(e)}
                                    invalid={validate}
                                />
                                <FormFeedback color="danger" className="my-2 text-center"><span className="text-white bg-danger rounded-circle mr-1">i</span> Proporciona un correo válido</FormFeedback>
                            </FormGroup>
                        </CardBody>
                        <CardText align="center">
                            <Button color="primary" className="text-white rounded-0 emigre-bold mb-5 px-5 mt-2" size="lg"
                                onClick = {sendMail}
                            >ENVIAR</Button>
                        </CardText>
                        <CardBody className={none}>
                            <p className="text-secondary text-center">Se acaba de enviar un correo electrónico a <br/> <span> {email} </span> <br/> para recuperar la contraseña de tu cuenta.</p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default RecoverPassword;
