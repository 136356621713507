import React from 'react';
import img from '../../assets/img/mantenimiento.jpg';
import './index.scss';


function Mantenimiento(props) {
    return (
        <div className='mantenimiento'>
            <img src={img} alt='mantenimiento' />
        </div>
    );
}

export default Mantenimiento;