import React,{useState, useEffect, useContext} from "react"
import { Container, Row, Col, Card } from 'reactstrap'
import PieChart from './PieChart/PieChart'
import DoughnutCharts from "./DougnutCharts/DougnutCharts";
import { contextApp } from '../../../App/contextApp';
import language from './language.json'

const PercentCategories = (props) => {

    const { dataCategoriesResume, dataCategories } = props;

    const { product, service, time, other } = dataCategories;

    //console.log('dataCategories', dataCategories)

    const {       
        currentLanguage
    } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

    },[currentLanguage])
    

    return (
        <Container tag="section">
            <Row className="mb-4">
                <Col xs={12}>
                    <h4 className="emigre-bold text-secondary text-uppercase">{texts.totalIncidentsByCat}</h4>
                </Col>
                
                <Col xs={12}>
                    <Card className="p-4">
                        <Row>
                            <Col xs={12} lg={6}>
                            
                                <PieChart dataCategoriesResume={dataCategoriesResume} texts={texts}/>
                            </Col>
                            <Col xs={12} lg={6}>
                                
                                <DoughnutCharts dataCategories={dataCategories} product={product} service={service} time={time} other={other} texts={texts}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default PercentCategories;