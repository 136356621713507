import React, { useState } from 'react';
import { Collapse, Table, Row, Col  } from 'reactstrap';
import { Line } from 'react-chartjs-2'


const Servicios = (props) => {

const { monthsLabel, dataIncidences, texts } = props;
const {CalidadAtencion, CalidadOrden, CalidadSucursal} = dataIncidences;
const [collapse, setCollapse] = useState(false);

const toggle = () => setCollapse(!collapse);

const getCalidadAtencion = () => {
    var out = CalidadAtencion;
    if(CalidadAtencion){
        if(CalidadAtencion.length === 1) {
            out = [null, CalidadAtencion[0], null];
        }
    }
    return out;
}

const getCalidadSucursal = () => {
    var out = CalidadSucursal;
    if(CalidadSucursal){
        if(CalidadSucursal.length === 1) {
            out = [null, CalidadSucursal[0], null];
        }
    }
    return out;
}

const getCalidadOrden = () => {
    var out = CalidadOrden;
    if(CalidadOrden){
        if(CalidadOrden.length === 1) {
            out = [null, CalidadOrden[0], null];
        }
    }
    return out;
}

const getMonthsLabel = () => {
    var out = monthsLabel;
    if(monthsLabel.length === 1) {
        out = ['nulo', monthsLabel[0], 'nulo' ];
    }
    return out;
}

const data = {
    labels: getMonthsLabel(),
    datasets:[{
        label: texts.atentionQuality,
        fill: false,
        lineTension: 0,
        backgroundColor: '#99de14',
        borderColor: '#99de14',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#99de14',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#99de14',
        pointHoverBorderColor: '#99de14',
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 20,
        data: getCalidadAtencion()

    },{
        label: texts.storeQuality,
        fill: false,
        lineTension: 0,
        backgroundColor: '#29bff0',
        borderColor: '#29bff0',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#29bff0',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#29bff0',
        pointHoverBorderColor: '#29bff0',
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 20,
        type: 'line',
        data: getCalidadSucursal(),
    },{
        label: texts.orderQuality,
        fill: false,
        lineTension: 0,
        backgroundColor: '#ff7534',
        borderColor: '#ff7534',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#ff7534',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#ff7534',
        pointHoverBorderColor: '#ff7534',
        pointHoverBorderWidth: 5,
        pointRadius: 5,
        pointHitRadius: 20,
        type: 'line',
        data: getCalidadOrden(),
    }

]
}

return (
<div>
    <div className='collapseHeader' onClick={toggle}>  
        <h4 className='emigre-bold text-secondary text-left text-white text-uppercase'>
            <i className="icomoon icon-servicio text-white ml-3 p-1"></i> {texts.service} <span><svg height="30" width="30" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className={collapse ? "css-6q0nyr-Svg" : "css-6q0nyr-Svg closed"}><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg></span>
        </h4>
    </div>
    <Collapse isOpen={collapse}>
        <Row>
            <Col xs={12} className='text-center'>
                <p className="d-inline mx-3 text-center text-uppercase">{texts.atentionQuality} <span className="legend time-answered"></span></p>
                <p className="d-inline mx-3 text-center text-uppercase">{texts.storeQuality}<span className="legend product-answered"></span></p>
                <p className="d-inline mx-3 text-center text-uppercase">{texts.incorrectOrder} <span className="legend other-answered"></span></p>
                <Line data={data} legend={{display:false}}></Line>
            </Col>

        </Row>
        <Row>
            <Col xs={12}>
                <Table bordered responsive>
                    <thead>
                        <tr>
                            <th scope="row" className="bg-secondary text-uppercase">{texts.service}</th>
                            {monthsLabel && monthsLabel.map((item, index) => {
                                return <th className="text-center bg-secondary" key={index}>{item}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-uppercase">{texts.atentionQuality}</td>
                            {CalidadAtencion && CalidadAtencion.map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                        <tr>
                            <td className="text-uppercase">{texts.storeQuality}</td>
                            {CalidadSucursal && CalidadSucursal.map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                        <tr>
                            <td className="text-uppercase">{texts.incorrectOrder}</td>
                            {CalidadOrden && CalidadOrden.map((item, index) => {
                            return <td className="text-center" key={index}>{item}</td>
                            })}
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    </Collapse>
</div>
);
}

export default Servicios;