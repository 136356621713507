/* eslint-disable */
import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Button, Card, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Layout from '../../layout/layout';
import HttpHandlerServices from '../../../services/httpService';
import { toast } from 'react-toastify';
import dots from '../../../assets/img/dots.gif';
import pizza from '../../../assets/img/makefg.png'
import './Chat.scss';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';



import { w3cwebsocket as W3CWebSocket } from "websocket";

const Chat = (props) => {
    
    const {className } = props;
    
    const [detail, setDetail] = useState([]);

    const [conversation, setConversation] = useState([]);

    const [preConversation, setPreConversation] = useState([]);

    const [message, setMessage] = useState([]);

    const [modal, setModal] = useState(false);
   
    const toggle = () => setModal(!modal);

    const [userData, setUserData] = useState({
        id: null,
        name: null,
        email: null,
        role: null   
    });

    const [pending, setPending] = useState([])

    const [client, setClient] = useState(null);

    const ktbots_id = props.match.params.ktbos_id;

    useEffect(()=> {

        setClient(new W3CWebSocket('wss://usa.wss.littlecaesars.hubcrm.net'));

    },[]);

    useEffect(()=> {

        if(client) {

            getUserData();
    
            getConversation();
    
            handlerSocket();

        }
        // eslint-disable-next-line
    },[client]);

   
    
    const closeCoversation = () => {

        client.onclose = function() {
           
        };

        setConversation([]);

        const endpoint = `/endconversation/${ktbots_id}`;

        const httpHandlerServices =  new HttpHandlerServices();

        httpHandlerServices.getMethod(endpoint).then(
            async (res) => {
                const data = await res;
                if(data) {
                    getConversation();
                }
            },
            async (err) => {
                const data = await err;
                if(data) getConversation();
            }
        )
    }


    const getConversation = () => {

        setPending([1])

        console.log(`/${props.match.params._conversation}/${props.match.params._id}`)
        
        const endpoint = `/${props.match.params._conversation}/${props.match.params._id}`;
       
        const httpHandlerServices =  new HttpHandlerServices();

        httpHandlerServices.getMethod(endpoint).then(

            async (res) => {
                const data = await res;

                setDetail(data.detail);

                setPreConversation([...data.messages]);

                setPending([]);

                console.log('data msg',data.messages)

                updateScroll();

            
            },
            async (error) => {
                const err = await error;
                if(err) {
                
                    toast.warning(`error`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 8000
                    });
                }
            }
        );
    }



    const getUserData = () => {

        const endpoint = '/user';

        const httpHandlerServices = new HttpHandlerServices();

        httpHandlerServices.getMethod(endpoint).then(

            async res => {

                const data = await res;

                setUserData(data);

            },
            error => {

                toast.error(``
                , {

                    position: toast.POSITION.BOTTOM_RIGHT,

                    autoClose: 5000

                });
            }
        );
    }


    const handlerSocket = () => {

        client.onerror = function() {
            toast.error(`error`
                , {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 8000
            });
        };

        client.onopen = function() {

            console.log('WebSocket Client Connected');
        
            function subscribe() {
                
               if (client.readyState === client.OPEN) {
                
                    client.send(`{"command" : "subscribe", "channel" : ${ktbots_id}}`)
                    
                }
            }
            
            subscribe();
            
        };
        
        
        client.onmessage = function(e) {
            
            if (typeof e.data === 'string') {

                
                
                let mesages = message;

                const res = JSON.parse(e.data);
                
                mesages.push(res);
                
                setMessage(message);
                
                setConversation([...mesages]);

                let pendingArray = [...pending]

                pendingArray.splice(0, 1);
               
                setPending(pendingArray);

                updateScroll();


            } 
        };
    }


    function updateScroll(){

        var element = document.getElementById("chatybox");

        if(element) element.scrollTop = element.scrollHeight;

    }

    const timeZoneTime = (currentTime) => {
        
        const time = currentTime
        const a = moment.tz(time, process.env.REACT_APP_TIMEZONE);
        return a
    }


    return (
        <Layout>

            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>¿REALMENTE DESEA CERRAR ESTA CONVERSACIÓN?</ModalHeader>
                <ModalBody>
                    Si cierra esta conversación no podra volverla a abrir
                </ModalBody>
                <ModalFooter>
                <Button color="primary" onClick={() =>{
                    closeCoversation();
                    toggle();
                } }>Si, Cerrar Conversación</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>

            <Container className="chat">
                <Row className="">
                    
                    <Col xs={12}>
                        <Card className="chat_box" color="dark">
                            <Row className="p-4">
                                <Col xs={12} md={4} className="border-right text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-tag mr-2"></i>Category</h6>
                                    <h6 className="text-uppercase text-white emigre-bold">{detail ? detail.complaint_type : 'N/A'}</h6>
                                </Col>
                                <Col xs={12} md={4} className="border-right text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-shop mr-2"></i>Store Number</h6>
                                    <h6 className="text-uppercase text-white emigre-bold">{detail.store ? detail.store.name : 'N/A'}</h6>
                                </Col>
                                <Col xs={12} md={4} className="text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-tag mr-2"></i>Reason</h6>
                                    {
                                        detail && 
                                        <h6 className="text-uppercase text-white emigre-bold">
                                            {detail.complaint_reason === 'more_15_with_queue' && ' More_than_15_Minutes'} 
                                            {detail.complaint_reason === 'more_10_with_queue' && ' More_than_10_Minutes'}
                                            {detail.complaint_reason === 'more_5_with_queue' && ' More_than_5_Minutes'}
                                            {detail.complaint_reason !== 'more_15_with_queue' && detail.complaint_reason !== 'more_10_with_queue' && detail.complaint_reason !== 'more_5_with_queue' ? detail.complaint_reason : ''}
                                        </h6>
                                    }
                                    
                                </Col>
                            </Row>
                        </Card>
                        <Card className="mb-3">
                            <Row className="p-4">
                                <Col xs={12} md={4} className="border-right text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-user_service mr-2"></i>Customer Name</h6>
                                    <h6 className="text-uppercase text-dark emigre-bold">{detail ? detail.name : 'N/A'}</h6>
                                </Col>
                                <Col xs={12} md={4} className="border-right text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-cel mr-2"></i>Phone Number</h6>
                                    <h6 className="text-uppercase text-dark emigre-bold">{detail ? detail.phone : 'N/A'}</h6>
                                </Col>
                                <Col xs={12} md={4} className="text-center">
                                    <h6 className="text-uppercase text-secondary emigre-bold"><i className="icomoon icon-mail mr-2"></i>Email</h6>
                                    <h6 className="text-dark emigre-bold">{detail ? detail.email : 'N/A'}</h6>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Card id='chatybox' className="p-5 chat_msn_box">
                            {/* contenido de la base de datos */} 
                            {
                            preConversation.map((ele, i) => (
                                <Col xs={12} key={ele.id}>
                                    {    /* CLIENT MESSAGE */
                                        ele.type === 'user' && 
                                        <Row className="message-row">
                            
                                            <Col xs={2} md={1} className="text-left flex-center">
                                                <i className="chat_icon icomoon icon-user_service"></i>
                                            </Col>
                                            <Col xs={10} md={11} className="p-0 text-left">
                                                <p className="emigre text-secondary m-0"><small><Moment fromNow ago>{timeZoneTime(ele.created_at)}</Moment></small></p>
                                                <p className={"text-dark m-0 emigre chat-text client capitalize titleLine" }>
                                                    {ele.type === 'user' ? detail.name : ele.cms_name}
                                                </p>
                                                {ele?.image64 ? <img className='imgChat' src={`data:image/jpeg;base64,${ele.image64}`} alt=''/> :
                                                
                                                <p className={"text-dark m-0 emigre chat-text client" }>
                                                    {ele?.message !== '' && ele?.image64 === '' && ele?.message}
                                                </p>
                                                }
                                                
                                            </Col>
                                        </Row> 

                                    }
                                    {   /* FACEBOOK AUTO MESSAGE */
                                        ele.cms_id === 1  && ele.viewed === 2 &&  ele.type === 'cms'  && 
                                        <Row className="message-row">
                                            <Col xs={2} md={1} className="text-left flex-center">
                                                <img className='auto-response-icon' src={pizza} alt='pizza-icon' />
                                            </Col>
                                            <Col xs={10} md={11} className="p-0 text-left">
                                                <p className="emigre text-secondary m-0"><small><Moment fromNow ago>{timeZoneTime(ele.created_at)}</Moment></small></p>
                                                <p className={"text-dark m-0 emigre chat-text client capitalize titleLine" }>
                                                    FACEBOOK
                                                </p>
                                                <p className={"text-dark m-0 emigre chat-text client" }>
                                                    {ele?.message !== '' && ele?.image64 === '' && ele?.message}
                                                </p>
                                                {ele?.image64 && <img className='imgChat' src={`data:image/jpeg;base64,${ele.image64}`} alt=''/>}
                                            </Col>
                                        </Row>
                                            
                                    } 
                                    {   /* SUPPORT */
                                        ele.type === 'cms' &&
                                        <Row className="message-row">
                                            <Col xs={10} md={11} className="p-0 text-right">
                                                <p className="emigre text-secondary m-0"><small><Moment fromNow ago>{timeZoneTime(ele.created_at)}</Moment></small></p>
                                                <p className={"text-dark m-0 emigre chat-text operator capitalize titleLine" }>
                                                    {ele.type === 'user' ? detail.name : ele.cms_name}
                                                </p>
                                                <p className={"text-dark m-0 emigre chat-text operator " }>
                                                    {ele?.message}
                                                </p>
                                            </Col>
                                            <Col xs={2} md={1} className="text-right flex-center">
                                                <i className="chat_icon icomoon icomoon icon-atc"></i>
                                            </Col>
                                        </Row>
                                    } 
                                    
                                </Col>

                            ))
                        }

                            {/* mensajes tiempo real */}

                            {
                                conversation.map((ele, i) => (

                                    <Col xs={12} key={ele.id}>
                                    {    /* CLIENT MESSAGE */
                                        ele.type === 'user' && 
                                        <Row className="message-row">
                            
                                            <Col xs={2} md={1} className="text-left flex-center">
                                                <i className="chat_icon icomoon icon-user_service"></i>
                                            </Col>
                                            <Col xs={10} md={11} className="p-0 text-left">
                                                <p className="emigre text-secondary m-0"><small><Moment fromNow ago>{timeZoneTime()}</Moment></small></p>
                                                <p className={"text-dark m-0 emigre chat-text client capitalize titleLine" }>
                                                    {ele.type === 'user' ? detail.name : ele.cms_name}
                                                </p>
                                                {ele?.image64 ? <img className='imgChat' src={`data:image/jpeg;base64,${ele.image64}`} alt=''/> :
                                                
                                                <p className={"text-dark m-0 emigre chat-text client" }>
                                                    {ele?.message !== '' && ele?.image64 === '' && ele?.message}
                                                </p>
                                                }
                                                
                                            </Col>
                                        </Row> 

                                    }
                                    {   /* FACEBOOK AUTO MESSAGE */
                                        ele.cms_id === 1  && ele.viewed === 2 &&  ele.type === 'cms'  && 
                                        <Row className="message-row">
                                            <Col xs={2} md={1} className="text-left flex-center">
                                                <img className='auto-response-icon' src={pizza} alt='pizza-icon' />
                                            </Col>
                                            <Col xs={10} md={11} className="p-0 text-left">
                                                <p className="emigre text-secondary m-0"><small><Moment fromNow ago>{timeZoneTime()}</Moment></small></p>
                                                <p className={"text-dark m-0 emigre chat-text client capitalize titleLine" }>
                                                    FACEBOOK
                                                </p>
                                                <p className={"text-dark m-0 emigre chat-text client" }>
                                                    {ele?.message !== '' && ele?.image64 === '' && ele?.message}
                                                </p>
                                                {ele?.image64 && <img className='imgChat' src={`data:image/jpeg;base64,${ele.image64}`} alt=''/>}
                                            </Col>
                                        </Row>
                                            
                                    } 
                                    {   /* SUPPORT */
                                        ele.type === 'cms' && 
                                        <Row className="message-row">
                                            <Col xs={10} md={11} className="p-0 text-right">
                                                <p className="emigre text-secondary m-0"><small><Moment fromNow ago>{timeZoneTime()}</Moment></small></p>
                                                <p className={"text-dark m-0 emigre chat-text operator capitalize titleLine" }>
                                                    {ele.type === 'user' ? detail.name : ele.cms_name}
                                                </p>
                                                <p className={"text-dark m-0 emigre chat-text operator " }>
                                                    {ele?.message}
                                                </p>
                                            </Col>
                                            <Col xs={2} md={1} className="text-right flex-center">
                                                <i className="chat_icon icomoon icomoon icon-atc"></i>
                                            </Col>
                                        </Row>
                                    } 
                                    
                                </Col>

                                ))
                            }
                            {
                                pending.map(ele => (
                                    
                                    <div key={ele} className='dots'>
                                        <img src={dots} alt='dots' />
                                    </div>

                                ))

                            } 
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                <Col xs={12}>
                        <Card color="secondary" className="text-white text-center p-2 rounded-0">
                            <Row>
                                <Col xs={12}>
                                    {
                                        detail.status_id === 1 ?
                                        <h5 className="m-0">Open</h5>
                                        : null
                                    }
                                    {
                                        detail.status_id === 2 ?
                                        <h5 className="m-0">In Progess</h5>
                                        : null
                                    }
                                    {
                                        detail.status_id === 3 ?
                                        <h5 className="m-0">Resolved</h5>
                                        : null
                                    }
                                    {
                                        detail.status_id === 4 ?
                                        <h5 className="m-0">Open</h5>
                                        : null
                                    }
                                </Col>
                            </Row>
                        </Card>
                </Col>
                </Row>
              
            </Container>
        </Layout>
    );
}

export default Chat;