import React from 'react'
import { BrowserRouter } from "react-router-dom"
import Router from "./Router"
import MainWrapper from "./MainWrapper"
import 'react-toastify/dist/ReactToastify.css';
import './App.scss'

function App(props) {

  return (
    <BrowserRouter>
      <MainWrapper>
        <Router />
      </MainWrapper>
    </BrowserRouter>
  )}
    
export default App;