import React, { useContext, useState, useEffect } from "react"
import { Container, Row, Col, Card } from 'reactstrap'
import { contextApp } from "../../../App/contextApp"
import IncidencesChart from "./IncidendesChart/IncidencesChart";
import language from './language.json';

const IncidencesByCategory = (props) =>{

    const { dataCategories } = props;

    const {product, service, time, other} = dataCategories; 

    const { selectValue, currentLanguage } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

    },[currentLanguage])
   

    const { monthsLabel } = selectValue;

    let sumaProduct = 0;

    let sumaService = 0;

    let sumaTime = 0;

    let sumaOther = 0;

    if(product && product[0]){

        sumaProduct = Object.values(product).reduce(((a,b) => a + b.total), 0);

    }

    if(service && service[0]){

        sumaService = Object.values(service).reduce(((a,b) => a + b.total), 0);

    }

    if(time && time[0]){

        sumaTime = Object.values(time).reduce(((a,b) => a + b.total), 0);

    }

    if(other && other[0]){

        sumaOther = Object.values(other).reduce(((a,b) => a + b.total), 0);
        
    }

    return (
        <Container tag="section" className="mb-5">
                    <Col xs={12}>
                        <h4 className="emigre-bold text-secondary text-uppercase">{texts.totalIncidentsByCategory}</h4>
                    </Col>
            <Row>
                <Col xs={12}>
                    <Card>
                        <Row className="p-4">
                            <Col xs={12} md={3} className="border-right d-flex align-items-center justify-content-center flex-column">
                                <h6 className="emigre-bold text-secondary text-uppercase"><i className="icon-servicio mr-2"></i>{texts.services}</h6>
                                <p className="whiteCapsule w-50">{sumaService}</p>
                            </Col>
                            <Col xs={12} md={3} className="border-right d-flex align-items-center justify-content-center flex-column">
                                <h6 className="emigre-bold text-secondary text-uppercase"><i className="icon-producto mr-2"></i>{texts.products}</h6>
                                <p className="whiteCapsule w-50">{sumaProduct}</p>
                            </Col>
                            <Col xs={12} md={3} className="border-right d-flex align-items-center justify-content-center flex-column">
                                <h6 className="emigre-bold text-secondary text-uppercase"><i className="icon-tiempo mr-2"></i>{texts.time}</h6>
                                <p className="whiteCapsule w-50">{sumaTime}</p>
                            </Col>
                            <Col xs={12} md={3} className="d-flex align-items-center justify-content-center flex-column">
                                <h6 className="emigre-bold text-secondary text-uppercase"><i className="icon-otros mr-2"></i>{texts.other}</h6>
                                <p className="whiteCapsule w-50">{sumaOther}</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-4">
                
                <Col xs={12}>
                    <Card className="p-4">
    
                        <IncidencesChart 
                            monthsLabel={monthsLabel} 
                            dataIncidences={props.dataIncidences}
                        />
                    </Card>
                </Col>
            </Row>
            
        </Container>
    )
}

export default IncidencesByCategory;