import React, {useState, useEffect} from "react"
import Select from 'react-select';
import HttpHandlerServices from '../../services/httpService';
import { Container, Row, Col, Card } from 'reactstrap'
import './Filters.scss';
import language from './language.json';

const Filters = (props) =>{
  
  const { updateRequestData, clearAll } = props;

  const [optionsFran, setOptionsFran] = useState([]);
  const [franValue, setFranValue] = useState(null);

  const [optionsSuc, setOptionSuc] = useState([]);
  const [sucValue, setSucValue] = useState(null);

  const [optionsStates, setOptionsStates] = useState([]);
  const [stateValue, setStateValue] = useState(null);

  const [optionsCities, setOptionsCities]= useState([]);
  const [citiesValue, setCitiesValue] = useState(null);

  const [texts] = useState(language.english);

  const colourStyles = {
      control: styles => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? '#ff7534'
            : null,
          color: isDisabled
            ? 'white'
            : isSelected,
          cursor: isDisabled ? 'not-allowed' : 'white',
    
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? 'white' : '#ff7534'),
          },
        };
      },
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor:'#ff7534',
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: 'white',
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: 'white',
        ':hover': {
          backgroundColor: data.color,
          color: '#dc3545',
        },
      }),
  };

  const handleCitiesChanges = (e) => {
    if(e) {
      setCitiesValue(e.value)
      const search = [];
      e.map(item => search.push(item.value))
      updateRequestData(search, 'cities')
      return
    }
    updateRequestData(['0'], 'cities')
  }

  const handleStatesChanges = (e) => {
    if(e) {
      setStateValue(e.value)
      const search = [];
      e.map(item => search.push(item.value))
      updateRequestData(search, 'states')
      //getCities(search)
      return
    }
    console.log('clear states')
   
    updateRequestData(['0'], 'states')
    
    setCitiesValue(null)
    
    madeClear('states')
  }
  
  const handleStoresChanges = (e) => {
    if(e) {
      setSucValue(e.value);
      const search = [];
      e.map(item => search.push(item.value));
      updateRequestData(search, 'stores');
      //getStates(search);
      return;
    }
    updateRequestData(['0'], 'stores');
    setStateValue(null)
    
    setCitiesValue(null)
   
    madeClear('stores')
  }

  const handleFranchisesChanges = (e) => {

    if(e) {
      setFranValue(e.value);
      const search = [];
      e.map(item => search.push(item.value));
      updateRequestData(search, 'franchise_id');
      getStores(search);
      return;
    }
    updateRequestData(['0'], 'franchise_id')
    setSucValue(null)
    setOptionSuc([])
    setStateValue(null)   
    setCitiesValue(null)
    madeClear('franchise')
    
  }

  const madeClear = (parent) => {
    clearAll(parent)
  }

  const getCities = () => {

    const httpHandlerServices = new HttpHandlerServices();

    const endpoint = '/showlocality';
    
    const data = {
      franchise_ids: 0,
      state_ids: 0,
      locality_ids: 0,
      stores_ids: 0,
    }
   
    httpHandlerServices.postMethod(endpoint, data).then(
        async (res) => {
            const data = await res;
            if(data){
               
                const array = data.map(item => {
                  const objCities = {
                    value: item.id,
                    label: item.locality
                  }
    
                  return objCities;
                  
                })
                setOptionsCities(array)
            }
        },
        async (error) => {
            const err = await error;
            if(err)
            console.log(err)
        }
    )
    
  }

  const getStates = () => {

    const httpHandlerServices = new HttpHandlerServices();

    const endpoint = '/showstate';
    
    const data = {
      franchise_ids: 0,
      state_ids: 0,
      locality_ids: 0,
      stores_ids: 0,
    }

    httpHandlerServices.postMethod(endpoint, data).then(
        async (res) => {
            const data = await res;
            if(data){
                
                const array = data.map(item => {
                  const objState = {
                    value: item.id,
                    label: item.state
                  }
    
                  return objState;
                  
                })
                setOptionsStates(array)
            }
        },
        async (error) => {
            const err = await error;
            if(err)
            console.log(err)
        }
    )
    
  }

  const getStores = (list) => {

    const httpHandlerServices = new HttpHandlerServices();

    const endpoint = '/showstoresfull';
    
    const data = {
      franchise_ids: list.toString(),
      state_ids: 0,
      locality_ids: 0,
      stores_ids: 0,
    }

    httpHandlerServices.postMethod(endpoint, data).then(
        async (res) => {
            const data = await res;
            if(data){
               
                const array = data.map(item => {
                  const objStore = {
                    value: item.id,
                    label: item.name
                  }
    
                  return objStore;
                  
                })
                setOptionSuc(array)
            }
        },
        async (error) => {
            const err = await error;
            if(err)
            console.log(err)
        }
    )
    
  }

  useEffect(()=>{
    const getFranchisesList = () => {
      const endpoit = '/showfranchise';
      const httpHandlerServices = new HttpHandlerServices();
      httpHandlerServices.getMethod(endpoit).then(
          async res => {
            const data = await res;
            
            const array = data.map(item => {
              const objFran = {
                value: item.id,
                label: item.franchise
              }

              return objFran;
              
            })
            setOptionsFran(array)

          },
          async error => {
              const err =  await error;
              console.log(err)
          }  
      );
  }
    getFranchisesList()
    getCities()
    getStates()
    
  },[])

  return(
    <Container tag="section" className="filters mb-5">
      <Card body tag="article" className="d-block z-indez-99">
        <Row xs={12}>
            <Col xs={12} md={3} className="mb-2">
              <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder={texts.filterByFranchise}
                  name="franchise" 
                  id="franchise"
                  closeMenuOnSelect={true}
                  isMulti
                  noOptionsMessage={() => texts.noMoreData}
                  styles={colourStyles}
                  options={optionsFran}
                  value={franValue}
                  theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#ff7534',
                        primary: '#ff7534',
                        neutral90: 'white',
                        primary50:"#ff7534",
                      },
                    })}
                  onChange={(e)=> {
                    handleFranchisesChanges(e)
                  }}
                />
            </Col>
            <Col xs={12} md={3} className="mb-2">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  name="sucursal" 
                  id="sucursal"
                  placeholder={texts.filterByBranch}
                  closeMenuOnSelect={true}
                  isMulti
                  options={optionsSuc}
                  value={sucValue}
                  noOptionsMessage={() => texts.noMoreData}
                  styles={colourStyles}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#ff7534',
                      primary: '#ff7534',
                      neutral90: 'white',
                      primary50:"#ff7534",
                    },
                  })}
                  isDisabled={optionsSuc.length < 1}
                  onChange={(e)=> handleStoresChanges(e)}
                />
            </Col>
            <Col xs={12} md={3} className="mb-2">
            <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder={texts.filterByState}
                    closeMenuOnSelect={true}
                    name="state" 
                    id="state"
                    isMulti
                    options={optionsStates}
                    value={stateValue}
                    noOptionsMessage={() => texts.noMoreData}
                    styles={colourStyles}
                    theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#ff7534',
                          primary: '#ff7534',
                          neutral90: 'white',
                          primary50:"#ff7534",
                        },
                      })}
                    onChange={(e)=> {
                      handleStatesChanges(e)
                    }}
                   
                    />
            </Col>
            <Col xs={12} md={3} className="mb-2">
            <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="city" 
                    id="city"
                    placeholder={texts.filterByCity}
                    closeMenuOnSelect={true}
                    isMulti
                    options={optionsCities}
                    value={citiesValue}
                    noOptionsMessage={() => texts.noMoreData}
                    styles={colourStyles}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#ff7534',
                        primary: '#ff7534',
                        neutral90: 'white',
                        primary50:"#ff7534",
                      },
                    })}
                    onChange={(e)=> handleCitiesChanges(e)}
                   
                    />
            </Col>
            
        </Row>
      </Card>
    </Container>
  )
}

export default Filters