import FileSaver from 'file-saver';

const baseUrl = process.env.REACT_APP_API_DOMAIN

const bearerToken = () => {
    
    const session =  JSON.parse(localStorage.getItem(process.env.REACT_APP_SECRET_LOCAL_KEY));
    
    if (session) {
       
        return `Bearer ${session.access_token}`;

    } else {
        
        window.location.href = process.env.REACT_APP_LOGIN_DOMAIN;
        
        return null; 

    }
    
}

const controller = new AbortController();

const signal = controller.signal;


export default class HttpHandlerServices { 

    abortFetching() {
        console.log('abort signal')
        controller.abort()
    }

    postMethod(endpoint, data) {
        
        const url = baseUrl + endpoint;

        return new Promise(
            
            async (resolve, reject) => {
                
                await fetch (url, {

                    method: 'POST',
                    body: JSON.stringify(data),
                    signal:signal,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': bearerToken()
                    }

                })
                .then(

                    res => res.json()

                )
                .catch(

                    error => reject(error)

                )
                .then(

                    response => resolve(response)

                );

            }

        );
    }

    postMethodFile(endpoint, data) {
        const url = baseUrl + endpoint;
        return new Promise(
            async (resolve, reject) => {
                await fetch (url, {
                    method: 'POST',
                    signal:signal,
                    body: JSON.stringify(data),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': bearerToken()
                    }
                })
                .then(res => {
                    return res.blob()
                })
                .catch(error => reject(error))
                .then(blob =>  {
                   return  resolve(FileSaver.saveAs(blob, 'report.csv'))
                });
            }
        );
    }

    getMethod (endpoint) {

        const url = baseUrl + endpoint;
    
        return new Promise(

            async (resolve, reject) => {

                await fetch(url, {

                    method: 'GET',
                    signal:signal,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': bearerToken()
                    }

                })
                .then(

                    res => res.json()

                )
                .catch (

                    error => reject(error)

                )
                .then (

                    response => resolve(response)

                )

            }

        );

    }

}
